@import "../../../../../scss/variables.scss";

.header-bottom {
  &--wrapper{
    display: none;

    @media screen and (min-width: 1201px) {
      display: block;
    }
  }


  &--fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    width: 100%;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }

  &__nav-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__nav-item {
    flex-grow: 1;
    transition: background-color $ms;

    img {
      margin-right: 10px;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 10px;
      width: 100%;
    }

    &--dropdown {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 20px;
      padding-bottom: 20px;
      cursor: pointer;

      .arrow {
        margin-left: 7px;
        transform: rotate(0);
        transition: transform $ms;
      }

      &:hover,
      &:focus {
        .arrow {
          transform: rotate(180deg);
          transition: transform $ms;
        }

        .header-bottom__dropdown {
          opacity: 1;
          visibility: visible;
          transition: visibility $ms, opacity $ms;
        }
      }
    }

    @media (hover) {
      &:hover,
      &:focus,
      &.active {
        background-color: #fbefd0;
        transition: background-color $ms;
      }
    }
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 2000;
    padding: 0px 0;
    width:auto;
    font-size: 1.6rem;
    box-shadow: 0 6px 13px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: visibility $ms, opacity $ms;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    &-item {
      overflow-wrap: anywhere;

      a {
        padding: 10px;
        justify-content: initial;
        transition: background-color $ms;
        white-space: nowrap;

        img {
          flex-shrink: 0;
        }

        @media (hover) {
          &:hover,
          &:focus,
          &.active {
            background-color: #fbefd0;
            transition: background-color $ms;
          }
        }
      }
    }
  }
}
