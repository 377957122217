@import "../variables.scss";

.checker {
  padding-top: 30px;

  @media (max-width: 767px) {
    padding-top: 15px;
  }

  &--utm {
    padding-top: 20px;
  }

  &__wrapper {
    position: relative;
    display: flex;

    @media (max-width: 767px) {
      flex-direction: column-reverse;
    }

    &--non-reverse {
      @media (max-width: 767px) {
        flex-direction: column;

        .checker__left {
          margin-top: 0;
          margin-bottom: 20px;
        }
      }
    }

    .loader--absolute {
      z-index: unset;
    }
  }

  &__title {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 18px;
    opacity: 0.8;
    line-height: 150%;

    span {
      display: inline-block;
      color: rgba(19, 20, 48, 0.6);
      font-weight: 400;
    }

    &-list {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      font-weight: 600;
      opacity: 0.8;

      li {
        &:not(:last-child) {
          margin-right: 20px;

          @media (max-width: 576px) {
            margin-right: 0;
            margin-bottom: 8px;
          }
        }

        span.red {
          color: #b6001f;
        }

      }

      @media (max-width: 576px) {
        display: block;
        font-size: 16px;
      }
    }
  }

  &__subtitle {
    margin-bottom: 15px;
    opacity: 0.8;
    font-size: 16px;
    line-height: 150%;

    p {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &--margin {
      margin-bottom: 20px;
    }
  }

  &__left {
    flex-shrink: 0;
    margin-right: 30px;
    max-width: 270px;
    width: 100%;

    &--wide {
      max-width: 370px;
    }

    &-timeout,
    &-speed,
    &-ip,
    &-country,
    &-quantity,
    &-checkboxes,
    &-host {
      margin-bottom: 20px;
    }

    &-utm {
      position: relative;
      padding-top: 30px;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      span {
        position: absolute;
        left: 0;
        top: 0;
        font-weight: 600;
        opacity: 0.8;
      }

      input {
        width: 100%;
        display: flex;
        align-items: center;
        min-height: 40px;
        background-color: #fff;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
        border-radius: 50px;
        font-size: 14px;
        padding: 5px 20px;
        transition: box-shadow $ms;

        @media (max-width: 576px) {
          padding: 5px 12px;
        }

        &:hover,
        &:focus {
          box-shadow: 0px 0px 2px #ffc42a;
          transition: box-shadow $ms;
        }
      }
    }

    &-subtitle {
      margin-top: 10px;
      font-size: 12px;
      line-height: 150%;
      opacity: 0.8;
    }

    @media (max-width: 767px) {
      margin-right: 0;
      margin-top: 20px;
      max-width: 100%;
    }
  }

  &-main {
    display: flex;
    flex-direction: row;
    max-width: none;
    flex-wrap: wrap;

    &__left {
      flex-basis: 30%;
      min-width: 270px;
      margin-right: 30px;

      &:last-child {
        margin-top: -195px;
      }

      @media (max-width: 761px) {
        &:last-child {
          margin-top: 0;
        }
      }
    }

    @media (max-width: 761px) {
      &__left {
        flex-basis: 100%;
        margin-right: 0;
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      flex-basis: 58%;
      flex-grow: 1;
      margin-bottom: 20px;

      &-textarea {
        display: flex;
        height: 328px;

        textarea {
          display: flex;
          overflow-y: auto;
          overflow-x: hidden;
          padding: 20px;
          height: 100%;
          resize: none;
          background-color: #fff;
          border-radius: 4px;
          transition: box-shadow $ms;

          &:hover,
          &:focus {
            box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
            transition: box-shadow $ms;
          }
        }
      }

      @media (max-width: 761px) {
        &-textarea {
          height: 150px;
        }
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-textarea {
      flex-grow: 1;
      box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);

      @media (max-width: 767px) {
        min-height: 150px;
      }


      textarea,
      div {
        overflow-y: auto;
        overflow-x: hidden;
        padding: 20px;
        width: 100%;
        height: 100%;
        resize: none;
        background-color: #fff;
        border-radius: 4px;
        transition: box-shadow $ms;

        @media (max-width: 767px) {
          padding: 12px;
          min-height: 150px;
          font-size: 16px;
        }
      }

      div {
        overflow-wrap: break-word;
        white-space: pre-line;
        word-break: break-all;
        max-height: 600px;

        &.success {
          color: #129300;
          background-color: #ade6a5;
        }

        &.error {
          color: #b6001f;
          background-color: #f3b3be;
        }

        p.success {
          color: #129300;
        }

        p.error {
          color: #b6001f;
        }

        @media (max-width: 767px) {
          max-height: 350px;
        }
      }

      textarea {
        &:hover,
        &:focus {
          box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
          transition: box-shadow $ms;
        }
      }

      &--error {
        textarea,
        div {
          box-shadow: 0 2px 15px rgba(214, 4, 4, 0.3);
        }
      }
    }

    &-subtext {
      margin-top: 25px;
      color: #6c6c6c;
      line-height: 150%;
      font-size: 16px;
    }

    &-btns {
      display: flex;
      align-items: center;
      margin-top: 20px;

      @media (max-width: 576px) {
        display: block;
      }
    }

    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 48px;
      padding: 5px 30px;
      border: 2px solid transparent;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);
      border-radius: 111px;
      transition: box-shadow $ms;

      &:disabled {
        opacity: 0.6;
        pointer-events: none;
      }

      img {
        margin-right: 10px;
        width: 18px;
        height: 18px;
      }

      &--dark {
        background-color: #131430;
        color: #fff;

        @media (hover) {
          &:hover,
          &:focus {
            box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.5);
            transition: box-shadow $ms;
          }
        }
      }

      &--light {
        color: #131430;
        background-color: #fff;

        @media (hover) {
          &:hover,
          &:focus {
            box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.3);
            transition: box-shadow $ms;
          }
        }
      }

      &:not(:last-child) {
        margin-right: 15px;

        @media (max-width: 576px) {
          margin-right: 0;
          margin-bottom: 15px;
        }
      }
    }
  }

  &__upload {
    margin-top: 8px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    label {
      cursor: pointer;
    }

    input {
      position: absolute;
      overflow: hidden;
      width: 1px;
      height: 1px;
      clip: rect(0 0 0 0);
    }

    span {
      font-size: 14px;
      opacity: 0.8;
    }
  }

  &__text {
    padding-top: 40px;

    &-inner {
      display: flex;
      align-items: center;

      @media (max-width: 767px) {
        display: block;
      }
    }

    &-left {
      display: flex;
      align-items: center;
      margin-right: 30px;
      max-width: 870px;

      img {
        margin-right: 20px;
        flex-shrink: 0;
        width: 30px;
        height: 30px;
      }

      p {
        opacity: 0.8;
        line-height: 150%;

        @media (max-width: 767px) {
          font-size: 16px;
        }
      }

      @media (max-width: 767px) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    &-right {
      width: 100%;
      max-width: 270px;

      @media (max-width: 767px) {
        margin-right: auto;
        margin-left: auto;
      }
    }
  }

  &__ip {
    &-input {
      position: relative;
      margin-bottom: 10px;

      input {
        display: flex;
        align-items: center;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 20px;
        padding-right: 55px;
        font-size: 16px;
        width: 100%;
        min-height: 40px;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
        border-radius: 50px;
        background-color: #fff;
        color: rgba(19, 20, 48, 0.8);

        -webkit-appearance: none;
        appearance: none;
        -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);

        &::placeholder{
          font-size: 14px;
        }

        &:hover,
        &:focus {
          box-shadow: 0px 0px 2px #ffc42a;
          transition: box-shadow $ms;

          -webkit-appearance: none;
          appearance: none;
          -webkit-box-shadow: 0px 0px 2px #ffc42a;
          -moz-box-shadow: 0px 0px 2px #ffc42a;
        }
      }

      &--error {
        input {
          box-shadow: 0px 0px 2px #d60404;

          -webkit-appearance: none;
          appearance: none;
          -webkit-box-shadow: 0px 0px 2px #d60404;
          -moz-box-shadow: 0px 0px 2px #d60404;
        }
      }
    }

    &-button {
      position: absolute;
      right: 0;
      top: 0;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      background-color: #131430;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06);

      &::after,
      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        border-radius: 4px;
        background-color: #fff;
        transform: translate(-50%, -50%);
      }

      &.add {
        &::after {
          width: 3px;
          height: 16px;
        }
      }

      &.remove {
        &::after {
          display: none;
        }
      }

      &::before {
        width: 16px;
        height: 3px;
      }
    }

    &-insert {
      position: relative;
      font-weight: 600;
      font-size: 14px;
      color: #ffc42a;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: -3px;
        width: 0;
        height: 1px;
        background-color: #ffc42a;
        transition: width $ms;
      }

      @media (hover) {
        &:hover,
        &:focus {
          &::before {
            width: 80%;
            transition: width $ms;
          }
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__tip {
    margin-top: 8px;
    text-align: center;
    font-size: 14px;
    opacity: 0.8;
  }

  &__results-container {
    padding-top: 10px;
  }

  &__results {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 45px;
    margin-bottom: 15px;

    @media (max-width: 992px) {
      flex-direction: column;
    }

    &-left {
      display: flex;
      font-weight: 600;

      @media (max-width: 992px) {
        margin-bottom: 15px;
      }

      @media (max-width: 767px) {
        flex-direction: column;
      }
    }

    &-title {
      margin-right: 50px;

      @media (max-width: 767px) {
        margin-right: 0;
        margin-bottom: 15px;
      }
    }

    &-text {
      .red {
        color: #b6001f;
      }

      &:not(:last-child) {
        margin-right: 25px;

        @media (max-width: 767px) {
          margin-right: 0;
          margin-bottom: 8px;
        }
      }
    }
  }

  &__table {
    position: relative;

    &-ip {
      position: absolute;
      bottom: calc(100% + 10px);
      left: 20%;
      z-index: 5;
      display: flex;
      align-items: center;
      color: #131430;
      font-size: 16px;
      background: #fff;
      box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      padding: 7px 16px;
      width: 230px;
      opacity: 0;
      visibility: hidden;
      transition: opacity $ms, visibility $ms;
      cursor: pointer;

      img {
        margin-right: 13px;
        width: 16px;
        height: 16px;
      }

      &::before {
        content: "";
        position: absolute;
        left: 20px;
        top: 100%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 4px 0 4px;
        border-color: #fff transparent transparent transparent;
      }
    }

    &-text {
      color: rgba(19, 20, 48, 0.8);
    }

    &-cursor {
      cursor: pointer;
    }

    &-country {
      display: flex;
      align-items: center;
      overflow: hidden;

      text-overflow: ellipsis;


      img {
        margin-right: 8px;
        width: 16px;
        height: 16px;
      }

      span {
        white-space: nowrap;
      }
    }

    &-link {
      color: #f1c654;
    }

    &:hover,
    &:focus {
      .checker__table-ip {
        opacity: 1;
        visibility: visible;
        transition: opacity $ms, visibility $ms;
      }
    }
  }

  &__anon {
    font-weight: 600;
  }

  &__download {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;

    @media (max-width: 576px) {
      display: block;
    }

    &-title {
      margin-right: 20px;
      font-weight: 600;

      @media (max-width: 576px) {
        margin-right: 0;
        margin-bottom: 15px;
      }
    }

    &-files {
      display: flex;
      align-items: center;
    }

    &-file {
      position: relative;
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        right: 0;
        bottom: -1px;
        width: 0;
        height: 1px;
        opacity: 0.5;
        background-color: currentColor;
        transition: width $ms;
      }

      img {
        margin-right: 10px;
        width: 20px;
        height: 20px;
      }

      &:not(:last-child) {
        margin-right: 20px;
      }

      @media (hover) {
        &:hover,
        &:focus {
          &::before {
            width: calc(100% - 30px);
            transition: width $ms;
          }
        }
      }
    }
  }

  &__head {
    margin-bottom: 20px;
    opacity: 0.8;
  }

  &__traffic {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid transparent;
      margin-bottom: 7px;
      font-weight: 600;
      color: rgba(19, 20, 48, 0.8);
      background-color: #fff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);
      border-radius: 111px;
      transition: border $ms;
      cursor: pointer;

      button {
        min-height: 42px;
        height: 100%;
        width: 100%;
        padding: 5px 30px;
      }

      &:not(:last-child) {
        margin-right: 12px;
      }

      &--active {
        border: 2px solid #ffc42a;
      }

      @media (hover) {
        &:hover,
        &:focus {
          border: 2px solid #ffc42a;
          transition: border $ms;
        }
      }

      @media (max-width: 767px) {
        font-size: 16px;
        padding: 4px 15px;
      }
    }
  }

  &__utm-url {
    margin-top: 15px;
  }

  &__load {
    padding-top: 15px;
    padding-bottom: 5px;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 10px;

    &-item {
      display: inline-block;
      width: 250px;
      position: relative;
      text-align: left;
      padding: 5px;
      padding-left: 25px;
      margin-bottom: 10px;
      margin-right: 10px;

      .spinner-container,
      .success-icon {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }

      .success-icon {
        font-size: 20px;
        color: #ffc42a;
      }
    }
  }
}

.checker__loader {
  width: 60px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid #0000;
  border-right-color: #ffc42a;
  position: relative;
  animation: l24 1s infinite linear;
}

.checker__loader:before,
.checker__loader:after {
  content: "";
  position: absolute;
  inset: -8px;
  border-radius: 50%;
  border: inherit;
  animation: inherit;
  animation-duration: 2s;
}

.checker__loader:after {
  animation-duration: 4s;
}

@keyframes l24 {
  100% {
    transform: rotate(1turn)
  }
}

.checker__loader-wraper {
  display: flex;
  align-items: center;
  justify-content: center;
}

