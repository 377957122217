@import "../../../scss/variables.scss";

.header {
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);

  @media (max-width: 992px) {
    position: sticky;
    top: 0;
    z-index: 2000;
    background-color: #fff;
  }
}
