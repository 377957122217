@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/Gilroy-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("../../assets/fonts/OpenSans-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("../../assets/fonts/OpenSans-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("../../assets/fonts/OpenSans-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "ProximaNova";
  src: url("../../assets/fonts/ProximaNova-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("../../assets/fonts/Lato-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("../../assets/fonts/Rubik-Medium.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Rubik-normal";
  src: url("../../assets/fonts/Rubik-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
