@import "./scss/settings/fonts.scss";
@import "./scss/settings/normalize.scss";
@import "./scss/variables.scss";

body {
  font-family: "OpenSans", sans-serif;
  font-weight: 400;
  font-size: 1.8rem;
  color: #131430;

  &.lock {
    overflow: hidden;

    // .wrapper {
    // transform: translateX(255px);
    // transition: transform $ms;
    // }
  }
}

// UI
@import "./scss/ui.scss";

// Components
@import "./scss/components/myIp.scss";
@import "./scss/components/checker.scss";
@import "./scss/components/react-data-table-styles.scss";

// Libs
@import "./scss/libs/react-toastify.scss";
@import "./scss/libs/modal-video.scss";

@import "./scss/keyframes.scss";

body
  > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
  display: none;
}
