.loader {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &--fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }

  &--absolute {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.2);
    transform: translate(-50%, -50%);
    backdrop-filter: blur(1px);
    pointer-events: none;
  }

  &__mini {
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-top-color: var(--color);
    border-radius: 50%;
    min-width: var(--width);
    min-height: var(--height);
    animation: spin 1s linear infinite;
  }

  &__inner {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    div {
      position: absolute;
      top: 33px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background-color: #ffc42a;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);

      &:nth-child(1) {
        left: 8px;
        animation: loading1 0.6s infinite;
      }

      &:nth-child(2) {
        left: 8px;
        animation: loading2 0.6s infinite;
      }

      &:nth-child(3) {
        left: 32px;
        animation: loading2 0.6s infinite;
      }

      &:nth-child(4) {
        left: 56px;
        animation: loading3 0.6s infinite;
      }
    }
  }
}

@keyframes loading1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes loading2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@keyframes loading3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
