@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 18px;
  height: 18px;
  border: 4px solid #fbefd0;
  border-top: 4px solid #ffc42a;
  border-radius: 50%;
  animation: spinner 0.8s linear infinite;
}
