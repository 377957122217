.section {
  &__title {
    font-size: 3.2rem;
    font-weight: 700;
    color: #131430;

    @media (max-width: 767px) {
      font-size: 2.4rem;
    }

    @media (max-width: 576px) {
      font-size: 2.2rem;
    }
  }
}

.select {
  &__indicator-separator {
    display: none;
  }

  &__control {
    border-radius: 25px !important;
    background-color: #fff;

    &--menu-is-open {
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3) !important;
      border-color: #ffc42a !important;

      &:hover,
      &:focus {
        border-color: #ffc42a !important;
        box-shadow: 0 0 0 1px #ffc42a !important;
      }
    }

    &--is-focused {
      box-shadow: 0 0 0 1px #ffc42a !important;
      border-color: #fff !important;
    }

    &:hover,
    &:focus {
      border-color: #ffc42a !important;
      box-shadow: 0 0 0 1px #ffc42a !important;
    }
  }

  &__multi-value__label {
    font-size: 14px !important;

    img {
      display: none;
    }

    @media (max-width: 767px) {
      font-size: 10px !important;
    }
  }

  &__single-value {
    font-size: 14px !important;

    img {
      margin-right: 8px;
    }
  }

  &__placeholder {
    font-size: 14px;
  }

  &__option {
    display: flex !important;
    align-items: center;
    font-size: 14px !important;
    cursor: pointer !important;

    img {
      margin-right: 7px;
      width: 18px;
      height: 18px;
    }

    &--is-focused {
      background-color: rgba(255, 196, 42, 0.2) !important;
    }

    &--is-selected {
      background-color: rgba(255, 196, 42, 0.8) !important;
      color: #000 !important;
    }
  }

  &__menu {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.non-clickable {
  pointer-events: none;
}

.grecaptcha-badge {
  visibility: hidden;
}

