@import "../../../../../scss/variables.scss";

.header-top {
  //border-bottom: 1px solid #ececec;
  //padding-top: 9px;
  //padding-bottom: 9px;


  &__wrapper {
    display: flex;
    align-items: center;

  }

  // Nav styles
  &__nav {
    display: block;
    margin-right: 70px;
    margin-left: auto;

    @media screen and (max-width: 1201px) {
      display: none;
    }

    &-list {
      display: flex;
      align-items: center;
    }

    &-item {
      &:not(:last-child) {
        margin-right: 60px;
      }

      a {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          left: 50%;
          width: 0%;
          height: 2px;
          background-color: #ffc42a;
          bottom: -6px;
          transform: translateX(-50%);
          transition: width $ms;
        }

        @media (hover) {
          &:hover,
          &:focus {
            &::before {
              width: 100%;
              transition: width $ms;
            }
          }
        }
      }
    }
  }

  // Lang styles
  &__lang {
    position: relative;
    margin-right: 40px;
    font-family: "Gilroy", sans-serif;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    -webkit-tap-highlight-color: transparent;

    z-index: 3000;

    @media (max-width: 576px) {
      font-size: 14px;

    }

    @media (max-width: 1200px) {
      z-index: 10;
    }

    &-current {
      position: relative;
      display: flex;
      align-items: center;
      z-index: 11;
      cursor: pointer;

      &::after {
        content: "";
        margin-left: 8px;
        width: 12px;
        height: 9px;
        background-image: var(--arrow);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        transform: rotate(0);
        transition: transform $ms;
        pointer-events: none;
      }
    }

    &-list {
      position: absolute;
      left: 50%;
      top: -10px;
      z-index: 10;
      border-radius: 4px;
      padding-top: 40px;
      width: calc(100% + 20px);
      box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      transform: translateX(-50%);
      transition: opacity $ms, visibility $ms;



      &-visible {
        opacity: 1;
        visibility: visible;
        transition: opacity $ms, visibility $ms;

      }

      &-hidden {
        opacity: 0;
        visibility: hidden;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 5px 10px;
      cursor: pointer;

      transition: background-color $ms;

      @media (hover) {
        &:hover,
        &:focus {
          background-color: rgba(255, 196, 42, 0.3);
          transition: background-color $ms;
        }
      }
    }

    img {
      margin-right: 6px;
      width: 24px;
      height: 24px;

      @media (max-width: 576px) {
        margin-right: 4px;
        width: 18px;
        height: 18px;
      }
    }

    .header-top__lang-current.rotated::after {
      transform: rotate(180deg);
      transition: transform $ms;
    }

    @media (max-width: 1200px) {
      order: 2;
      margin-right: 0;
    }
  }

  @media (max-width: 1200px) {
    padding-top: 16px;
    padding-bottom: 16px;

    .logotype {
      order: 1;
      margin-right: auto;
      margin-left: auto;
    }
  }
}
