.rdt_TableHeadRow {
  border: 2px solid rgba(19, 20, 48, 0.65) !important;
  border-radius: 4px 4px 0px 0px;
  background-color: #131430 !important;

  & .rdt_TableCol {
    color: #fff !important;
    font-weight: 600;
    font-size: 16px;
  }
}

.rdt_TableCell {
  position: static !important;
}

.rdt_TableBody {
  & .rdt_TableRow {
    font-size: 14px !important;
    min-height: 60px !important;
    position: relative !important;
  }
}

.rdt_TableCol_Sortable {
  &:hover,
  &:focus {
    color: #fff !important;
  }
}
