@import "../../../scss/variables.scss";

.footer {
  margin-top: 40px;
  border: 2px solid rgba(19, 20, 48, 0.65);
  padding-top: 45px;
  padding-bottom: 45px;
  background-color: #131430;

  @media (max-width: 767px) {
    margin-top: 50px;
  }

  &__wrapper {
    display: flex;

    @media (max-width: 992px) {
      display: block;
    }
  }

  &__left {
    margin-right: 100px;

    @media (max-width: 1200px) {
      margin-right: 40px;
    }

    @media (max-width: 992px) {
      margin-right: 0;
      margin-bottom: 25px;
      text-align: center;
    }
  }

  &__logo {
    margin-bottom: 20px;
    max-width: 238px;
    width: 100%;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    @media (max-width: 992px) {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__credits {
    margin-bottom: 30px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);

    p {
      display: block;

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }

  &__language {
    &-title {
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 16px;
      color: #fff;
    }

    .select__control {
      background-color: transparent !important;
    }

    .select__single-value {
      color: #fff;
    }

    @media (max-width: 992px) {
      max-width: 300px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-right: 80px;

      @media (max-width: 1200px) {
        margin-right: 40px;
      }

      @media (max-width: 992px) {
        margin-right: 0;
        margin-bottom: 25px;
      }
    }

    &-title {
      margin-bottom: 15px;
      font-weight: 700;
      font-size: 20px;
      color: #fff;
    }

    &-wrapper {
      display: flex;

      @media (max-width: 992px) {
        justify-content: center;
      }

      @media (max-width: 576px) {
        display: block;
      }
    }

    &-list {
      color: #fff;
      font-size: 16px;
      max-width: 145px;

      &:not(:last-child) {
        margin-right: 100px;

        @media (max-width: 1200px) {
          margin-right: 40px;
        }

        @media (max-width: 576px) {
          margin-right: auto;
          margin-bottom: 20px;
        }
      }

      &-item {
        opacity: 0.8;
        line-height: 140%;
        transition: opacity $ms;

        &:not(:last-child) {
          margin-bottom: 13px;
        }

        @media (hover) {
          &:hover,
          &:focus {
            opacity: 1;
            transition: opacity $ms;
          }
        }
      }

      @media (max-width: 576px) {
        margin-right: auto;
        margin-left: auto;
        max-width: 100%;
      }
    }

    @media (max-width: 992px) {
      text-align: center;
    }
  }
}
