.seo {
  padding-top: 60px !important;

  @media (max-width: 767px) {
    padding-top: 30px !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 20px;
    font-weight: 700 !important;
    line-height: 150% !important;
    color: #131430 !important;

    @media (max-width: 767px) {
      margin-bottom: 15px !important;
    }
  }

  img {
    height: auto !important;
    max-width: 100% !important;
    object-fit: cover !important;
  }

  p + h1,
  p + h2,
  p + h3,
  p + h4,
  p + h5,
  ul + h1,
  ul + h2,
  ul + h3,
  ul + h4,
  ul + h5,
  ol + h1,
  ol + h2,
  ol + h3,
  ol + h4,
  ol + h5 {
    margin-top: 40px !important;

    @media (max-width: 767px) {
      margin-top: 20px !important;
    }
  }

  h1 {
    font-size: 32px !important;

    @media (max-width: 767px) {
      font-size: 26px !important;
    }
  }

  h2 {
    font-size: 28px !important;

    @media (max-width: 767px) {
      font-size: 24px !important;
    }

    @media (max-width: 576px) {
      font-size: 22px !important;
    }
  }

  h3 {
    font-size: 24px !important;

    @media (max-width: 767px) {
      font-size: 20px !important;
    }
  }

  h4 {
    font-size: 20px !important;

    @media (max-width: 767px) {
      font-size: 18px !important;
    }
  }

  h5 {
    font-size: 18px !important;

    @media (max-width: 767px) {
      font-size: 16px !important;
    }
  }

  p {
    line-height: 150% !important;
    opacity: 0.8 !important;

    @media (max-width: 767px) {
      font-size: 16px;
    }

    & + p {
      margin-top: 12px !important;
    }
  }

  a {
    font-weight: 700 !important;
    color: #ffc42a !important;

    @media (hover) {
      &:hover,
      &:focus {
        text-decoration: underline !important;
      }
    }

    @media (max-width: 767px) {
      font-size: 16px !important;
    }
  }

  ul,
  ol {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  ul {
    li {
      position: relative !important;
      margin-left: 20px !important;
      padding-left: 16px !important;
      line-height: 150% !important;
      opacity: 0.8 !important;

      &::before {
        content: "" !important;
        position: absolute !important;
        left: 0 !important;
        top: 11px !important;
        border-radius: 50% !important;
        width: 6px !important;
        height: 6px !important;
        background-color: #ffc42a !important;
      }

      & + li {
        margin-top: 12px !important;
      }

      @media (max-width: 767px) {
        margin-left: 5px !important;
        font-size: 16px !important;
      }
    }
  }

  ol {
    counter-reset: section;
    list-style: none !important;

    li {
      position: relative !important;
      padding-left: 36px !important;
      line-height: 150% !important;
      opacity: 0.8 !important;

      @media (max-width: 767px) {
        padding-left: 25px !important;
      }

      &::before {
        counter-increment: section;
        content: counters(section, ".") "." !important;
        position: absolute !important;
        left: 15px !important;
        top: 0 !important;
        font-size: 18px !important;
        color: #ffc42a !important;

        @media (max-width: 767px) {
          left: 0 !important;
        }
      }

      & + li {
        margin-top: 12px !important;
      }
    }
  }

  section {
    margin-top: 40px !important;

    @media (max-width: 767px) {
      overflow-x: auto;
      margin-top: 20px !important;
    }

    & + p,
    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + ul,
    & + ol {
      margin-top: 20px !important;
    }

    &:first-child {
      margin-top: 0 !important;
    }
  }

  blockquote {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  table {
    border-collapse: collapse !important;
    border-radius: 4px !important;
    width: 100% !important;
    min-width: 580px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1) !important;
    background-color: #fff !important;
    margin-top: 40px;
    margin-bottom: 40px;

    @media (max-width: 767px) {
      border: 1px solid #ececec !important;
    }

    th {
      padding: 12px 20px !important;
      font-weight: 600 !important;
      font-size: 16px !important;
      color: #fff !important;
      background-color: #131430 !important;

      &:first-child {
        border-radius: 4px 0 0 0 !important;
      }

      &:last-child {
        border-radius: 0 4px 0 0 !important;
      }

      @media (max-width: 576px) {
        padding: 12px 10px !important;
        font-size: 14px !important;
      }
    }

    tbody {
      tr {
        &:not(:last-child) {
          border-bottom: 1px solid #ececec !important;
        }

        &:last-child {
          border-bottom-right-radius: 4px !important;
          border-bottom-left-radius: 4px !important;
        }
      }

      td {
        padding: 14px 20px !important;
        min-height: 55px !important;
        font-size: 14px !important;
        line-height: 150% !important;
        color: rgba(19, 20, 48, 0.8) !important;

        &:first-child {
          border-bottom-left-radius: 4px !important;
        }

        &:last-child {
          border-bottom-right-radius: 4px !important;
        }

        &:not(:last-child) {
          border-right: 1px solid #ececec !important;
        }

        @media (max-width: 576px) {
          padding: 12px 10px !important;
        }
      }
    }

    &:first-child {
      margin-top: 0;
      margin-bottom: 40px;
    }

    &:last-child {
      margin-top: 40px;
      margin-bottom: 0;
    }
  }
}
