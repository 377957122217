@import "../variables.scss";

.my-ip {
  padding-top: 60px;

  @media (max-width: 767px) {
    padding-top: 40px;
  }

  &__text {
    margin-bottom: 24px;
    line-height: 150%;
    color: rgba(19, 20, 48, 0.8);

    @media (max-width: 767px) {
      font-size: 16px;
      margin-bottom: 15px;
    }
  }

  &__top {
    color: #fff;
    background-color: #131430;
    border: 2px solid rgba(19, 20, 48, 0.65);
    border-radius: 4px;
    padding: 30px;
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    &-adress {
      display: flex;
      align-items: center;
      margin-right: 80px;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      &-title {
        flex-shrink: 0;
        margin-right: 60px;
        font-weight: 600;
        font-size: 20px;

        @media (max-width: 1200px) {
          margin-right: 30px;
          font-size: 18px;
        }

        @media (max-width: 992px) {
          margin-right: 0;
          margin-bottom: 10px;
          font-size: 16px;
        }
      }

      &-ip {
        overflow: hidden;
        max-width: 250px;
        font-weight: 700;
        font-size: 22px;
        white-space: nowrap;
        text-overflow: ellipsis;

        @media (max-width: 1200px) {
          font-size: 24px;
        }

        @media (max-width: 992px) {
          font-size: 20px;
        }

        @media (max-width: 767px) {
          max-width: 100%;
          word-break: break-all;
          white-space: initial;
          text-align: center;
          text-overflow: initial;
        }
      }

      @media (max-width: 1200px) {
        margin-right: 50px;
      }

      @media (max-width: 992px) {
        display: block;
        margin-right: 30px;
      }

      @media (max-width: 767px) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    &-country {
      display: flex;
      align-items: center;
      margin-right: 15px;
      font-weight: 600;
      font-size: 20px;

      img {
        margin-right: 15px;
        width: 30px;
        height: 30px;
      }

      @media (max-width: 767px) {
        justify-content: center;
        margin-bottom: 20px;
      }
    }

    @media (max-width: 992px) {
      padding: 20px 15px;
    }

    @media (max-width: 767px) {
      display: block;
      text-align: center;
    }
  }

  &__change-ip {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    padding-left: 20px;
    padding-right: 20px;
    min-height: 48px;
    color: #131430;
    background-color: #ffc42a;
    border: 2px solid #ffc42a;
    border-radius: 50px;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    transition: background-color $ms, color $ms;

    @media (hover) {
      &:hover,
      &:focus {
        color: #fff;
        background-color: transparent;
        transition: background-color $ms, color $ms;
      }
    }

    @media (max-width: 767px) {
      margin-right: auto;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;

    @media (max-width: 992px) {
      display: block;
    }
  }

  &__table {
    display: flex;
    flex: 0 1 49%;
    border-radius: 4px;

    table {
      width: 100%;
      border-collapse: collapse;

      tr {
        &:first-child {
          border-radius: 4px;
        }

        td:first-child {
          padding: 20px 30px;
          background-color: #131430;
          color: #fff;
          max-width: 210px;

          @media (max-width: 576px) {
            padding: 10px 12px;
            font-size: 14px;
          }
        }

        td:last-child {
          display: flex;
          align-items: center;
          padding: 20px 30px;
          word-break: break-all;
          background-color: #fff;

          img {
            width: 22px;
            height: 22px;
            margin-right: 14px;

            @media (max-width: 576px) {
              margin-right: 6px;
              width: 15px;
              height: 15px;
            }
          }

          @media (max-width: 576px) {
            padding: 10px 12px;
            font-size: 16px;
          }
        }
      }
    }

    @media (max-width: 992px) {
      margin-bottom: 20px;
    }
  }

  &__map {
    flex: 0 1 49%;
    padding: 20px 30px;
    background-color: #fff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    &-title {
      margin-bottom: 14px;
      font-weight: 600;
      font-size: 30px;

      @media (max-width: 1200px) {
        font-size: 24px;
      }

      @media (max-width: 576px) {
        font-size: 18px;
        margin-bottom: 8px;
      }
    }

    &-container {
      margin-bottom: 20px;
      height: 255px;

      @media (max-width: 576px) {
        margin-bottom: 10px;
      }
    }

    &-subtitle {
      margin-bottom: 15px;
      font-weight: 700;
      font-size: 20px;
      line-height: 150%;
      color: rgba(19, 20, 48, 0.8);

      @media (max-width: 1200px) {
        margin-bottom: 10px;
        font-size: 18px;
      }

      @media (max-width: 576px) {
        font-size: 16px;
      }
    }

    &-text {
      margin-bottom: 30px;
      line-height: 150%;
      opacity: 0.8;
      font-size: 16px;

      @media (max-width: 1200px) {
        margin-bottom: 20px;
        font-size: 14px;
      }
    }

    &-btn {
      margin-right: auto;
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 250px;
      width: 100%;
      min-height: 48px;
      color: #131430;
      font-weight: 700;
      font-size: 16px;
      background-color: #ffc42a;
      border: 2px solid #ffc42a;
      border-radius: 50px;
      transition: background-color $ms;
      cursor: pointer;

      @media (hover) {
        &:hover,
        &:focus {
          background-color: #fff;
          transition: background-color $ms;
        }
      }
    }

    @media (max-width: 576px) {
      padding: 12px;
    }
  }

  &__caution {
    margin-top: 60px;
    display: flex;
    align-items: flex-start;

    @media (max-width: 767px) {
      margin-top: 35px;
    }

    img {
      flex-shrink: 0;
      width: 30px;
      height: 30px;
      margin-right: 20px;

      @media (max-width: 576px) {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }

    &-text {
      p {
        opacity: 0.8;
        line-height: 150%;

        &:not(:last-child) {
          margin-bottom: 15px;

          @media (max-width: 767px) {
            margin-bottom: 8px;
          }
        }
      }

      @media (max-width: 767px) {
        font-size: 16px;
      }

      @media (max-width: 576px) {
        font-size: 14px;
      }
    }
  }
}
