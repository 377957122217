@import "../../../scss/variables.scss";

.faq {
  padding-top: 60px;

  @media (max-width: 767px) {
    padding-top: 30px;
  }

  .section__title {
    margin-bottom: 30px;
  }

  &__item {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    border-radius: 35px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #fff;

    @media (max-width: 767px) {
      border-radius: 25px;
      padding-left: 15px;
      padding-right: 15px;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &-head {
      padding-top: 18px;
      padding-bottom: 18px;
      font-weight: 600;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      @media (max-width: 767px) {
        padding-top: 15px;
        padding-bottom: 15px;
      }

      @media (max-width: 576px) {
        font-size: 16px;
      }
    }

    &-toggler {
      margin-left: 10px;
      flex-shrink: 0;
      width: 10px;
      height: 20px;
      transform: rotate(0deg);
      transition: transform $ms;
    }

    &-body {
      max-height: 0;
      overflow: hidden;
      line-height: 150%;
      box-sizing: content-box;
      transition: max-height 0.3s, padding-bottom 0.3s;

      @media (max-width: 767px) {
        font-size: 16px;
      }

      @media (max-width: 576px) {
        font-size: 14px;
      }
    }

    &--active {
      .faq__item-toggler {
        transform: rotate(180deg);
        transition: transform $ms;
      }

      .faq__item-body {
        max-height: 500px;
        padding-bottom: 25px;
        transition: max-height 0.3s, padding-bottom 0.3s;
      }
    }
  }

  &__text {
    margin-top: 40px;
    opacity: 0.8;
    line-height: 150%;

    p {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    @media (max-width: 767px) {
      margin-top: 25px;
      font-size: 16px;
    }
  }
}
