.menu-wraper{
  position: relative;
}

.arrow{
  margin-left: 8px;
  transition: transform 0.3s;

}


.close_btn {
  position: absolute;
  top: 0;
  right: -44px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.12);
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.60);
  z-index: 50;
  display: none;
  backdrop-filter: blur(2px);

  &.active {
    display: block;
  }
}

.header__mobile {
  position: fixed;
  top: 20px;
  height: calc(100vh - 20px);
  width: 100%;
  max-width: 256px;
  z-index: 100;
  padding: 24px 20px 50px 20px;
  background-color: #fff;
  transform: translateX(-100%);
  transition: transform 0.5s ease;



  &.active {
    transform: translateX(0);
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  &-item {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &-accordion {
    display: flex;
    align-items: center;
    padding: 8px;
    background-color: transparent;
    color: #212121;
    cursor: pointer;
    font-size: 18px;
    width: 100%;
    text-align: left;
    border: none;
    border-radius: 6px;
    outline: none;
    transition: 0.4s;

    -webkit-tap-highlight-color: transparent;

    &.active,
    &:hover {
      background-color: rgba(255, 238, 176, 0.50);
      transition: background-color 0.3s;
    }

    &.active{
      .arrow {
        transform: rotate(180deg);
      }
    }
  }

  &-sublist {
    background-color: transparent;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;


  }

  &-subitem {
    -webkit-tap-highlight-color: transparent;
    a {
      position: relative;
      display: block;
      margin-left: 8px;
      padding: 10px 0;
      padding-left: 24px;
      font-size: 18px;

      img {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }

      &:hover {
        color: #f1c654;
        text-decoration: underline;
      }
    }


  }
}

.languageWraper{
  display: flex;

  margin-top: 24px;
  margin-left: 8px;
}

.navWraper{
  height: 100%;
  overflow-y: auto;
}
