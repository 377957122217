@import "../../../../../../../scss/variables.scss";

.burger {
  &__mobile-wrapper {
    display: none;

    @media screen and (max-width: 1200px) {
      display: block;
    }
  }

  &__btn {
    position: relative;
    z-index: 15;
    display: block;
    flex-shrink: 0;
    width: $burgerWidth;
    height: $burgerHeight;
    background-color: transparent;

    &::after,
    &::before,
    span {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: $burgerLineHeight;
      background-color: $burgerBgColor;
    }

    &::before {
      top: 0;
      transition: transform $ms, top $ms;
    }

    &::after {
      bottom: 0;
      transition: transform $ms, bottom $ms;
    }

    span {
      top: 7px;
      transition: transform $ms;
    }

    &--active {
      display: none;

      span {
        transform: scale(0);
        transition: transform $ms;
      }

      &::before {
        top: 7px;
        transform: rotate(45deg);
        transition: transform $ms, top $ms;
      }

      &::after {
        bottom: 7px;
        transform: rotate(-45deg);
        transition: transform $ms, bottom $ms;
      }
    }
  }

  &__wrapper {
    position: relative;

    display: none;

    @media screen and (min-width: 1201px) {
      display: block;
    }

    &:hover,
    &:focus {
      .burger__dropdown {
        opacity: 1;
        visibility: visible;
        transition: opacity $ms, visibility $ms;
      }
    }
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 10;
    margin-top: 5px;
    border-radius: 4px;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 230px;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    transition: opacity $ms, visibility $ms;

    &-item {
      a {
        display: block;
        padding: 6px 20px;
        width: 100%;
        transition: background-color $ms;

        @media (hover) {
          &:hover,
          &:focus {
            background-color: rgba(255, 196, 42, 0.3);
            transition: background-color $ms;
          }
        }
      }
    }
  }
}
