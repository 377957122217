@import "../../../scss/variables.scss";


.radio {
  &__wrapper {
    margin-bottom: 20px;

    select {
      width: 100%;
      min-height: 40px;
      padding: 8px;
      border-radius: 50px;
      background-color: #fff;

      font-size: 16px;
      color: rgba(19, 20, 48, 0.8);
      cursor: pointer;
      text-align: center;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
      background-image: url("../../../../public/img/ui/arrow.svg");
      background-repeat: no-repeat;
      background-position: calc(100% - 12px) center;

      -webkit-appearance: none;
      appearance: none;
      -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);

      &:hover
      {
        box-shadow: 0px 0px 2px #ffc42a;
        transition: box-shadow $ms;

        -webkit-appearance: none;
        appearance: none;
        -webkit-box-shadow: 0px 0px 2px #ffc42a;
        -moz-box-shadow: 0px 0px 2px #ffc42a;
      }
    }

  }

  &__title {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 18px;
    color: rgba(19, 20, 48, 0.8);
  }

  &__list {
    &--horizontal {
      display: flex;
      flex-wrap: wrap;

      .radio__item {
        margin-top: 5px;
        margin-bottom: 5px;

        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }

    &--vertical {
      .radio__item {
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }

    &--fifty {
      display: flex;
      flex-wrap: wrap;

      .radio__item {
        flex: 0 1 50%;

        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }
  }

  &__item {
    label {
      cursor: pointer;
    }

    input {
      position: absolute;
      overflow: hidden;
      width: 1px;
      height: 1px;
      clip: rect(0 0 0 0);

      &:checked + span {
        &::after {
          opacity: 1;
          visibility: visible;
          transition: opacity $ms, visibility $ms;
        }
      }
    }

    span {
      position: relative;
      display: block;
      padding-left: 24px;
      font-size: 14px;
      color: rgba(19, 20, 48, 0.8);

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
        background-color: #fff;
        transform: translateY(-50%);
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 3px;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        background-color: #131430;
        transform: translateY(-50%);
        opacity: 0;
        visibility: hidden;
        transition: opacity $ms, visibility $ms;
      }
    }
  }
}




