@import "../../../scss/variables.scss";

.page-head {
  padding-top: 20px;

  //&__wrapper {
  //  display: flex;
  //  justify-content: space-between;
  //
  //  h1 {
  //    margin-top: 20px;
  //    font-weight: 700;
  //    font-size: 32px;
  //
  //    @media (max-width: 1200px) {
  //      margin-bottom: 20px;
  //    }
  //
  //    @media (max-width: 767px) {
  //      font-size: 24px;
  //    }
  //
  //    @media (max-width: 576px) {
  //      font-size: 22px;
  //    }
  //  }
  //
  //  @media (max-width: 1200px) {
  //    display: block;
  //    text-align: center;
  //  }
  //}

  &__youtube {
    display: flex;
    align-items: center;
    margin-top: 15px;
    cursor: pointer;

    img {
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }

    p {
      font-weight: 600;
      font-size: 20px;
      color: #131430;

      @media (max-width: 767px) {
        font-size: 18px;
      }

      @media (max-width: 370px) {
        font-size: 16px;
      }
    }

    @media (max-width: 1200px) {
      justify-content: center;
    }
  }

  &__data {
    display: flex;
    margin-left: 15px;
    max-width: 500px;
    width: 100%;

    @media (max-width: 1200px) {
      margin-right: auto;
      margin-left: auto;
    }

    @media (max-width: 576px) {
      flex-direction: column;
      align-items: center;
    }

    //a {
    //  @media (hover) {
    //    &:hover,
    //    &:focus {
    //      text-decoration: underline;
    //    }
    //  }
    //}

    &-ip {
      margin-right: 15px;
      width: 100%;
      max-width: 238px;

      @media (max-width: 576px) {
        margin-right: 0;
        margin-bottom: 15px;
      }
    }

    &-anon {
      width: 100%;
      max-width: 238px;
    }

    &-your-ip {
      position: relative;
      height: 40px;
      margin-bottom: 10px;
      a {
        overflow: hidden;
        height: 40px;
        display: block;
        margin-bottom: 10px;
        padding-right: 15px;
        padding-left: 15px;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
        font-weight: 600;
        font-size: 14px;
        background-color: #fff;
        transition: 0.3s height;
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;
        right: 0;
        border-radius: 20px;
        text-decoration: none;

        & > div {
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          border-radius: 111px;
          padding-right: 15px;
          padding-left: 15px;
          height: 40px;
        }

        .name {
          flex-shrink: 0;
        }

        .ip {
          overflow: hidden;
          margin-left: 5px;
          font-weight: 700;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      &:hover {
        position: relative;
        height: 40px;
        margin-bottom: 10px;
        a {
          height: 80px;
        }
      }
    }

    &-link {
      display: block;
      font-size: 12px;
      text-align: center;
      color: #131430;
      opacity: 0.8;
    }

    &-anon-score {
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        width: 100%;
        height: 40px;
        max-width: 238px;
        font-weight: 600;
        font-size: 14px;
        border-radius: 111px;
        color: #fff;
        background-color: #80b65b;
      }

      span {
        margin-left: 5px;
        font-weight: 700;
      }
    }
  }

  &__text {
    margin-top: 15px;
    line-height: 150%;
    color: rgba(19, 20, 48, 0.8);
    text-align: justify;

    span,
    a {
      color: #f1c654;

      @media (hover) {
        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }

    p {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    @media (max-width: 1200px) {
      text-align: center;
    }

    @media (max-width: 576px) {
      font-size: 16px;
    }
  }

  &__list-title {
    margin-top: 20px;
    margin-bottom: 10px;
    opacity: 0.8;

    &--question {
      display: flex;
      align-items: center;

      img {
        opacity: 1;
        margin-right: 20px;

        @media (max-width: 576px) {
          margin-right: 10px;
        }
      }
    }

    @media (max-width: 576px) {
      font-size: 16px;
    }
  }

  &__list {
    margin-bottom: 50px;

    &--question {
      margin-left: 30px;
      margin-bottom: 30px;

      @media (max-width: 767px) {
        margin-left: 0;
      }
    }

    &--margin {
      &-20 {
        margin-top: 20px;
      }

      &-15 {
        margin-top: 15px;
      }
    }

    li {
      position: relative;
      margin-left: 20px;
      padding-left: 16px;
      opacity: 0.8;
      line-height: 150%;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 10px;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background-color: #ffc42a;
      }

      &:not(:last-child) {
        margin-bottom: 12px;
      }

      @media (max-width: 576px) {
        margin-left: 0;
        font-size: 16px;
      }
    }

    span {
      font-weight: 700;
    }

    @media (max-width: 767px) {
      margin-bottom: 25px;
    }
  }

  &__list-subtitle {
    opacity: 0.8;

    a {
      color: #ffc42a;

      &:hover {
        text-decoration: underline;
      }
    }

    &--question {
      margin-left: 50px;

      @media (max-width: 767px) {
        margin-left: 0;
      }
    }

    @media (max-width: 576px) {
      font-size: 16px;
    }
  }
}
