@import "../../../scss/variables.scss";

.section {
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 111px;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
    height: 48px;
    text-align: center;
    min-width: 270px;
    font-size: 16px;
    border: 2px solid transparent;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    font-weight: 600;
    transition: background-color $ms, color $ms, border-color $ms;

    &:disabled {
      pointer-events: none;
      opacity: 0.6;
    }

    &--light {
      color: #131430;
      background-color: #fff;

      @media (hover) {
        &:hover,
        &:focus {
          border-color: #131430;
          background-color: #131430;
          color: #fff;
          transition: background-color $ms, color $ms, border-color $ms;
        }
      }
    }

    &--dark {
      color: #fff;
      background-color: #131430;

      @media (hover) {
        &:hover,
        &:focus {
          border-color: #131430;
          background-color: transparent;
          color: #131430;
          transition: background-color $ms, color $ms, border-color $ms;
        }
      }
    }

    &--plain {
      position: relative;
      display: block;
      margin-right: auto;
      margin-left: auto;
      font-size: 14px;
      text-align: center;
      color: rgba(19, 20, 48, 0.8);

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        bottom: -4px;
        width: 0;
        height: 0.5px;
        background-color: rgba(19, 20, 48, 0.4);
        transform: translateX(-50%);
        transition: width $ms;
      }

      @media (hover) {
        &:hover,
        &:focus {
          &::before {
            width: 100%;
            transition: width $ms;
          }
        }
      }
    }

    @media (hover) {
      &:hover,
      &:focus {
        box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.15);
        transition: background-color $ms, color $ms, border-color $ms;
      }
    }
  }
}
